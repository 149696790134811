const messages = {
    en: {
        too_many_values: 'Please use one number only',
        empty_transaction: 'Please type in a transaction',
        value_not_found: 'Please add a numeric value',
        year: "Year",
        menu: {
            transactions: 'Incomes & Expenses',
            planner: 'Planner',
            import: 'Import',
            explorer: 'Explorer',
            categories: 'Categories',
            settings: 'Settings',
            logout: 'Logout'
        },
        settings: {
            changePassword: 'Change password',
            currentPass: 'Current password',
            newPass1: 'New password',
            newPass2: 'New password again',
            errors: {
                passwordTooShort: "Password is too short",
                passwordsNotMatching: "Passwords don't match",
                invalidCurrentPassword: "Current password is invalid",
            },
            success: 'Successful password change',
            language: 'Language',
            invite: 'Invite a user',
            subscription: 'Subscription type',
            annually: 'annually',
            monthly: 'monthly',
            freeTrial: 'free trial',
            nextPayment: 'Next payment due at',
        },
        login: {
            userName: 'User name',
            password: 'Password',
            loginButton: 'Login',
        },
        transactions: {
            pageDesc: "You can add incomes and expenses here",
            transactions: "Add transactions",
            description: "Description",
            value: "Value",
            date: "Date",
            action: "Action",
            placeholder: "Type in the transaction value and description: 1500 salary OR -900 coffee",
            tooltip: "Type in the transaction and hit Enter to store. E.g.: -600 coffee, 1500 got paid. You can input one transaction at a time."
        },
        stats: {
            total: "Total:",
            saved: "Saved:",
            start: "Start",
            end: "End",
            description: "Description",
            value: "Value",
            today: "Today",
            thisWeek: "This week",
            thisMonth: "This month",
            yearToDate: "Year to Date",
            previousMonth: "Previous month",
            income: "Income:",
            balance: "Balance:",
            monthBack: "< Month",
            monthForward: "Month >",
            categoryFilter: "Category filter",
            monthly: "End-of-month balances",
            summary: "Spending",
            actions: "Actions"
        },
        rt: {
            recurringTransactions: "Recurring transactions",
            description: "Description",
            value: "Value",
            action: "Action",
            placeholder: "-120000 rent",
            tooltip: "Type in a recurring transaction and hit Enter to store. E.g.: 250000 salary, -120000 rent. You can input one transaction at a time. Currently the monthly frequency is supported.",
            percentage: "% of full income"
        },
        currentCash: {
            sum: "Total money",
            income: "Income / month",
            out: "Expense / month",
            money: "Income - Expense / month",
            cashflow: "Current monthly balance",
            emergencyFund: "6 month emergency fund"
        },
        forecast: {
            forecastLength: "Forecast length",
            button: "Forecast",
            tooltip: "Set the number of months to forecast"
        },
        categories: {
            help: "Words you specify here will be grouped to the categories",
            name: "Specify a name for your category",
            words: "Specify the words that are in this category",
            nameTh: "Category name",
            wordsTh: "Words to match",
            actionTh: "Action",
            tooltip: {
                name: "Category name",
                words: "Words that belong to this category. Comma separated list."
            }
        }, import: {
            help: 'Import your bank account history. We support CSV files from OTP SmartBank at the moment.',
            importDate: 'Last import date:',
            noImportYet: 'No data'
        },
        forgotPassword: {
            sendButton: 'Send password reset email'
        },
        reset: {
            password1: 'Password',
            password2: 'Password confirmation',
            noMatch: 'Passwords don\'t match',
            resetButton: 'Set password'
        }
    },
    hu: {
        year: "Év",
        too_many_values: 'Kérlek egy számot használj',
        empty_transaction: 'Kérlek adj meg egy tranzakciót',
        value_not_found: 'Kérlek add meg az összeget',
        menu: {
            transactions: 'Kiadások & Bevételek',
            planner: 'Tervező',
            import: 'Importálás',
            explorer: 'Felfedező',
            categories: 'Kategóriák',
            settings: 'Beállítások',
            logout: 'Kijelentkezés'
        },
        settings: {
            changePassword: 'Jelszó változtatás',
            currentPass: 'Jelenlegi jelszó',
            newPass1: 'Új jelszó',
            newPass2: 'Új jelszó újra',
            errors: {
                passwordTooShort: "A jelszó túl rövid",
                passwordsNotMatching: "A jelszavak nem egyeznek",
                invalidCurrentPassword: "Téves jelenlegi jelszó",
            },
            success: 'Sikeres jelszóváltoztatás',
            language: 'Nyelv',
            invite: 'Felhasználó meghívása',
            subscription: 'Előfizetési státusz',
            annually: 'éves',
            monthly: 'havi',
            freeTrial: 'próbaidőszak',
            nextPayment: 'Következő fizetési esedékesség'
        },
        login: {
            userName: 'Felhasználónév',
            password: 'Jelszó',
            loginButton: 'Bejelentkezés',
        },
        transactions: {
            pageDesc: "Adj hozzá kiadást és bevételt",
            transactions: "Tranzakció hozzáadása",
            description: "Leírás",
            value: "Érték",
            date: "Dátum",
            action: "Műveletek",
            placeholder: "Írd le a tranzakció értékét és leírását: 300000 fizetés VAGY -780 kávé",
            tooltip: "Írd be a tranzakciót és üss Enter a rögzítéshez. Példák: -600 kávé, 200000 fizetés. Egyszerre csak egy tranzakciót adj meg."
        },
        stats: {
            total: "Összes költés:",
            saved: "Megtakarított:",
            start: "Kezdő dátum",
            end: "Utolsó dátum",
            description: "Leírás",
            value: "Érték",
            today: "Ma",
            thisWeek: "Ezen a héten",
            thisMonth: "Ebben a hónapban",
            yearToDate: "Év elejétől máig",
            previousMonth: "Előző hónapban",
            income: "Bevétel:",
            balance: "Egyenleg:",
            monthBack: "< Hónap",
            monthForward: "Hónap >",
            categoryFilter: "Szűrés kategóriára",
            monthly: "Havi egyenlegek",
            summary: "Költések",
            actions: "Szerkesztés"
        },
        rt: {
            recurringTransactions: "Rendszeres tranzakciók",
            description: "Leírás",
            value: "Érték",
            action: "Műveletek",
            placeholder: "-120000 lakbér",
            tooltip: "Írj be egy rendszeres kiadást vagy bevételt és üss Entert a rögzítéshez. Példák: 250000 fizetés, -120000 lakbér. Egyszerre egy tételt írj be. Jelenleg a havi rendszeresség van támogatva.",
            percentage: "Teljes bevétel %-a"
        },
        currentCash: {
            sum: "Összes pénz",
            income: "Bevétel / hó",
            out: "Kiadás / hó",
            money: "Bevétel - kiadás / hó",
            cashflow: "Aktuális havi egyenleg",
            emergencyFund: "6 havi vésztartalék"
        },
        forecast: {
            forecastLength: "Hónapok száma",
            button: "Előrejelzés",
            tooltip: "Add meg, hogy hány hónapot számoljunk előre"
        },
        categories: {
            help: "Itt tudod beállítani a saját kategóriáidat. A Felfedező ezek alapján rendszerezi a kiadásaidat.",
            name: "Kategória neve",
            words: "Kategória szavai",
            nameTh: "Név",
            wordsTh: "Szavak",
            actionTh: "Műveletek",
            tooltip: {
                name: "A kategória neve. Példa: Kaja",
                words: "A kategóriába tartozó tételek nevei. Vesszővel elválaszott lista. Példa: kávé,étterem,kisbolt"
            }
        }, import: {
            help: 'Importáld a banki számlatörténeted. Jelenleg az OTP SmartBankból letölthető CSV formátumú fájlokat támogatjuk.',
            importDate: 'Legutóbbi importálás dátuma:',
            noImportYet: 'Nincs adat'
        },
        forgotPassword: {
            sendButton: 'Jelszó emlékeztető küldése'
        },
        reset: {
            password1: 'Jelszó',
            password2: 'Jelszó megerősítés',
            noMatch: 'A jelszavak nem egyeznek',
            resetButton: 'Jelszó beállítása'
        }
    }
}

export default {
    messages: messages
}

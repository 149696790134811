<template>
  <div class="w3-col">
    <div class="w3-row-padding">
      <div class="w3-col">
        <h1>{{ $t("menu.settings") }}</h1>
        <div class="w3-card">
          <h3>{{ $t("settings.changePassword") }}</h3>
          <div class="w3-row">
            <div class="input-field-container w3-col l6">
              <label for="currentPassword"
                >{{ $t("settings.currentPass") }}
              </label>
              <input
                id="currentPassword"
                v-model="currentPassword"
                type="password"
                autocomplete="currentPassword-password"
                :class="{
                  'invalid-input': errors.has('invalidCurrentPassword'),
                }"
              />
              <label for="newfirst">{{ $t("settings.newPass1") }} </label>

              <input
                id="newfirst"
                v-model="password1"
                type="password"
                autocomplete="new-password"
                :class="{
                  'invalid-input':
                    errors.has('passwordTooShort') ||
                    errors.has('passwordsNotMatching'),
                }"
              />

              <label for="newsecond">{{ $t("settings.newPass2") }} </label>
              <input
                id="newsecond"
                v-model="password2"
                type="password"
                autocomplete="new-password"
                :class="{
                  'invalid-input':
                    errors.has('passwordTooShort') ||
                    errors.has('passwordsNotMatching'),
                }"
              />
            </div>
            <div class="w3-col l6">
              <span>
                <ul>
                  <li v-for="err in errors" :key="err">
                    {{ $t("settings.errors." + err) }}
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <button
            :disabled="
              currentPassword == '' ||
              password1 == '' ||
              password2 == '' ||
              errors.has('passwordTooShort') ||
              errors.has('passwordsNotMatching')
            "
            class="w3-button w3-round-xxlarge w3-green"
            @click="updatePassword"
          >
            {{ $t("settings.changePassword") }}
          </button>
          <hr />
          <h3>{{ $t("settings.subscription") }}: {{$t("settings." + subscription.type)}}, {{subscription.price}} HUF</h3>
          <h4>{{ $t("settings.nextPayment") }}: {{ subscription.nextPaymentDueAt }}</h4>
          <hr />
          <h3>{{ $t("settings.language") }}</h3>
          <select v-model="language">
            <option value="en">English</option>
            <option value="hu">Magyar</option>
          </select>
          <hr />
          <h3>{{ $t("settings.invite") }}</h3>
          <div class="w3-row">
            <div class="w3-col w3-half input-field-container">
              <input
                id="invite"
                type="email"
                v-model="emailToInvite"
                placeholder="user@gmail.com"
              />
            </div>
          </div>
          <button
            @click="inviteUser()"
            :disabled="emailToInviteInvalid"
            id="inviteUser"
            class="w3-button w3-round-xxlarge w3-green"
          >
            Invite
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from "../apiClient/settings";
import Invite from "../apiClient/invite";
import LanguageFunctions from "../languageFunctions";
import Formatters from "../utils/formatters";

export default {
  name: "Settings",
  data: function () {
    return {
      currentPassword: "",
      password1: "",
      password2: "",
      errors: new Set(),
      language: "",
      emailToInvite: "",
      emailToInviteInvalid: true,
      subscription: {
        type: '-',
        price: 0,
        nextPaymentDueAt: ''
      }
    };
  },
  watch: {
    password1(nv) {
      if (nv.length < 6 && nv.length > 0) {
        this.errors.add("passwordTooShort");
      } else {
        this.errors.delete("passwordTooShort");
      }

      if (this.password2 != "" && nv != this.password2) {
        this.errors.add("passwordsNotMatching");
      } else if (this.password2 != "" && nv == this.password2) {
        this.errors.delete("passwordsNotMatching");
      }
    },
    password2(nv) {
      if (this.password1 != "" && nv != this.password1) {
        this.errors.add("passwordsNotMatching");
      } else if (this.password1 != "" && nv == this.password1) {
        this.errors.delete("passwordsNotMatching");
      }
    },
    language(nv) {
      let self = this;
      Settings.setLanguage({ language: this.language })
        .then(() => {
          self.$root.$i18n.locale = nv;
          LanguageFunctions.set(nv);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emailToInvite() {
      this.emailToInviteInvalid = !this.$el
        .querySelector("#invite")
        .checkValidity();
    },
  },
  created: function () {
    function getLanguage() {
      let language = "en";
      if (localStorage.getItem("language")) {
        language = localStorage.getItem("language");
      }

      if (window.ENV && window.ENV.language) {
        language = window.ENV.language;
      }

      return language;
    }
    this.language = getLanguage();

    var self = this
    Settings.get().then((data) => {
      self.subscription = data.subscription
      if(data.subscription.type == 'free_trial') {
        self.subscription.type = 'freeTrial'
      }
      self.subscription.nextPaymentDueAt = Formatters.date(self.subscription.nextPaymentDueAt)
      self.subscription.price = Formatters.money(self.subscription.price)
    })
  },
  methods: {
    updatePassword: function () {
      var self = this;
      Settings.post(this.requestParams())
        .then((resp) => {
          if (resp.message != "success") {
            self.errors.add(resp.message);
          } else {
            self.currentPassword = "";
            self.password1 = "";
            self.password2 = "";
            self.errors.clear();
            alert(this.$t("settings.success"));
          }
        })
        .catch();
    },
    requestParams: function () {
      return {
        currentPassword: this.currentPassword,
        password1: this.password1,
        password2: this.password2,
      };
    },
    inviteUser: function () {
      Invite.inviteUser(this.emailToInvite)
        .then((resp) => {
          console.log(resp);
        })
        .catch((resp) => {
          console.log(resp);
        });
    },
  },
};
</script>

<style>
.input-field-container input {
  margin-bottom: 10px;
}

v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.invalid-input {
  border: 2px solid red !important;
}
</style>


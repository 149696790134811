<template>
  <div v-if="loaded">
    <nav
      id="mySidebar"
      class="w3-sidebar w3-bar-block w3-animate-left hide-on-mobile bold"
      :class="{ 'sidebar-open': showMenu, 'sidebar-closed': !showMenu }"
      style="
        text-align: center !important;
        background-image: url('Background.png');
        background-position-y: -300px;
      "
    >
      <img
        src="logo3.svg"
        style="width: 70%; margin-top: 20px; margin-bottom: 20px"
      >

      <div>
        <a
          href="#/app/transactions"
          class="w3-bar-item w3-button"
          :class="{ 'active-menu': isSelected('#/app/transactions') }"
        >{{
          $t("menu.transactions")
        }}</a>
        <a
          href="#/app/planner"
          class="w3-bar-item w3-button"
          :class="{ 'active-menu': isSelected('#/app/planner') }"
        >{{
          $t("menu.planner")
        }}</a>
        <a
          href="#/app/explorer"
          class="w3-bar-item w3-button"
          :class="{ 'active-menu': isSelected('#/app/explorer') }"
        >{{
          $t("menu.explorer")
        }}</a>
        <a
          href="#/app/import"
          class="w3-bar-item w3-button"
          :class="{ 'active-menu': isSelected('#/app/import') }"
        >{{
          $t("menu.import")
        }}</a>
        <a
          href="#/app/categories"
          class="w3-bar-item w3-button"
          :class="{ 'active-menu': isSelected('#/app/categories') }"
        >{{
          $t("menu.categories")
        }}</a>
        <a
          href="#/app/settings"
          class="w3-bar-item w3-button"
          :class="{ 'active-menu': isSelected('#/app/settings') }"
        >{{
          $t("menu.settings")
        }}</a>
        <button
          class="w3-bar-item w3-button w3-round logout-red"
          @click="logout()"
        >
          {{ $t("menu.logout") }}
        </button>
      </div>
    </nav>
    <!-- <div
      class="hide-on-mobile"
      :class="{ 'content-to-right': showMenu }"
      style="
        position: fixed;
        top: 0px;
        background-color: white;
        width: 100%;
        background-image: url('Background.png');
      "
    >
      <button
        class="w3-button w3-xlarge"
        style="background-image: url('Background.png')"
        @click="toggleMenu()"
      >
        &#9776;
      </button>
    </div> -->
    <div
      :class="{ 'content-to-right': showMenu }"
      class="w3-container padding-top-10"
      style="max-width: 112rem; margin: auto"
    >
      <current-cash />
      <router-view />
    </div>
    <nav class="w3-bar w3-bottom hide-on-desktop mobile-nav">
      <div>
        <a
          href="#/app/transactions"
          class="w3-bar-item w3-button"
        >
          <i class="fa fa-money" />
        </a>
        <a
          href="#/app/planner"
          class="w3-bar-item w3-button"
        >
          <i class="fas fa-pencil-ruler" />
        </a>
        <a
          href="#/app/explorer"
          class="w3-bar-item w3-button"
        >
          <i class="fas fa-binoculars" /></a>
        <a
          href="#/app/import"
          class="w3-bar-item w3-button"
        >
          <i class="fas fa-upload" /></a>
        <a
          href="#/app/categories"
          class="w3-bar-item w3-button"
        >
          <i class="far fa-list-alt" /></a>
        <a
          href="#/app/settings"
          class="w3-bar-item w3-button"
        >
          <i class="fas fa-cogs" />
        </a>
        <button
          class="w3-bar-item w3-button w3-round"
          @click="logout()"
        >
          <i class="fas fa-sign-out-alt" />
        </button>
      </div>
    </nav>
  </div>
  <div v-else>
    <div class="cssload-container">
      <div class="cssload-shaft1" />
      <div class="cssload-shaft2" />
      <div class="cssload-shaft3" />
      <div class="cssload-shaft4" />
      <div class="cssload-shaft5" />
      <div class="cssload-shaft6" />
      <div class="cssload-shaft7" />
      <div class="cssload-shaft8" />
      <div class="cssload-shaft9" />
      <div class="cssload-shaft10" />
    </div>
  </div>
</template>

<script>
import Login from "./apiClient/login";
import CurrentCash from "./components/CurrentCash.vue";

export default {
  name: "App",
  components: { CurrentCash },
  data() {
    return {
      showMenu: true,
      demoMode: process.env.VUE_APP_ENV == "demo",
      loaded: true,
      activeMenu: ''
    };
  },
  beforeCreate: function () {
    if (process.env.VUE_APP_ENV == "demo") {
      return;
    }
  },
  methods: {
    toggleMenu: function () {
      this.showMenu = !this.showMenu;
    },
    logout: function () {
      var self = this;
      Login.logout().then(function () {
        self.$router.replace("/login");
      });
    },
    isSelected: (page) => {
      return page == window.location.hash
    }
  },
};
</script>

<style>
body {
  background-color: #efefef;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* .v-sidebar-menu {
  color: black;
} */

.vsm--item {
  color: black;
  list-style: none;
}

.vsm--link {
  font-size: 24pt;
  color: black;
}

.vsm--toggle-btn {
  background: transparent;
}

.v-sidebar-menu {
  background-color: transparent;
  /* margin-top: 5.3em;
  margin-left: 0.6em; */
}

td,
th {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

td {
  table-layout: fixed;
  max-width: 10rem;
}

.tr-delete {
  cursor: pointer;
  text-align: right;
}

.tr-delete:hover {
  cursor: pointer;
  color: red;
  transition: 0.3s ease;
}

.td-delete {
  text-align: center;
}

.w3-card {
  padding: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: white;
  margin-bottom: 1rem;
}

.sidebar-open {
  width: 10%;
  /* display: block; */
  /* transition: 0.4s ease; */
}

.sidebar-closed {
  width: 0%;
  /* display: none; */
  /* transition: 0.4s ease; */
}

.content-to-right {
  margin-left: 10% !important;
  transition: 0.4s ease;
}

@media only screen and (max-width: 768px) {
  .content-to-right {
    margin-left: 0 !important;
    padding-top: 5px !important;
  }
}

.app-container {
  border-radius: 20px 0 0 0;
  left: -10px;
  top: -39px;
  position: relative;
  z-index: 999;
  background-color: #efefef;
  padding-top: 1em;
  height: calc(100% - 50px);
}

.active-menu {
  background-color: #93ca94;
  color: white;
}

.padding-top-10 {
  padding-top: 10px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="email"],
select {
  /*border: 2px solid #2ec2e1!important;*/
  border: 2px solid #51ae56!important;
  border-radius: 33px;
  background-color: #f8f8f8;
  width: 100%;
  transition: ease-in-out 0.2s;
  height: 3.4em;
  padding-left: 15px;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="email"]:focus,
select:focus {
  border: 2px solid #2ec2e1!important;
  border-radius: 33px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 20%);
  transition: ease-in-out 0.2s;
}

label {
  font-size: 17px;
}

label i {
  cursor: pointer;
}

.mobile-nav {
  text-align: center !important;
  background-image: url("../public/Background.png");
  background-position-y: -300px;
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.logout-red {
  background-color: #e54d43;
}

.w3-button {
  font-weight: 700;
}
</style>

import Api from '../index'

let path = '/sessions'

async function post(input) {
    return Api.postResponse(path, input)
}

async function get() {
    return Api.getResponse(path)
}

async function logout() {
    return Api.remove(path)
}

export default {
    post: post,
    get: get,
    logout: logout
}

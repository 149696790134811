import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import MainApp from './MainApp.vue'
import App from './App.vue'

import Login from './Login.vue'
import ForgotPassword from './ForgotPassword.vue'
import PasswordReset from './PasswordReset.vue'
import Join from './Join.vue'
import Subscription from './Subscription.vue'

import Transactions from './components/Transactions.vue'
import Planner from './components/Planner.vue'
import ImportData from './components/ImportData.vue'
import Stats from './components/Stats.vue'
import Categories from './components/Categories.vue'
import Settings from './components/Settings.vue'

import mitt from 'mitt'
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';

import Locales from './locales'
import { createI18n } from "vue-i18n"
import LanguageFunctions from './languageFunctions'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

window.Credential = 'include'

const routes = [
  {
    path: '/app',
    component: App,
    name: 'Transactions',
    children: [
      { path: 'transactions', component: Transactions, name: 'Transactions' },
      { path: 'planner', component: Planner, name: 'Planner' },
      { path: 'explorer', component: Stats, name: 'Explorer' },
      { path: 'import', component: ImportData, name: 'Import Data' },
      { path: 'categories', component: Categories, name: 'Categories' },
      { path: 'settings', component: Settings, name: 'Settings' },
    ]
  },
  { path: '/login', component: Login },
  { path: '/forgot', component: ForgotPassword },
  { path: '/', component: MainApp, children: [{ path: '/subscription', component: Subscription }]},
  { path: '/reset', component: PasswordReset },
  { path: '/j', component: Join },
  
]

const router = createRouter({
  routes: routes,
  history: createWebHashHistory()
})

router.beforeEach((to) => {
  if (to.matched.length == 0) {
    return '/'
  }
})

const app = createApp(MainApp)
app.component('PulseLoader', PulseLoader)
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.tippy = tippy;

const i18n = createI18n({
  locale: LanguageFunctions.get(), // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: Locales.messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

app.use(i18n)
app.use(router)
  .mount('#mainApp')

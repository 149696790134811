import Api from '../index'

let baseRequestJSON = {
    headers: {
    },
    cache: 'no-cache',
    redirect: 'follow',
    credentials: 'include',
    referrer: 'no-referrer',
}
let path = '/import'
function post(input) {
    let request = baseRequestJSON
    request.method = 'POST'
    request.body = input
    return fetch(Api.apiHost + path, request);
}

async function get() {
    return Api.get(path)
}

export default {
    post: post,
    get: get
}

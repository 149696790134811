<template>
  <div class="w3-row-padding">
    <div class="w3-col" style="padding-bottom: 1rem">
      <h1>{{ $t("menu.explorer") }}</h1>
      <div class="w3-bar">
        <div class="w3-bar-item w3-border-right stat-green w3-button w3-round-xxlarge space-after"
          @click="showSummaryBtn">
          {{ $t("stats.summary") }}
        </div>
        <div class="w3-bar-item w3-border-right stat-green w3-button w3-round-xxlarge" @click="showMonthlyBtn">
          {{ $t("stats.monthly") }}
        </div>
      </div>
    </div>
  </div>
  <div class="w3-row-padding">
    <!-- <div class="w3-col m12 l12" :style="{ display: showSummary }">
      <div class="w3-card">
        <transaction-bar-chart :transactions="transactions" />
      </div>
    </div> -->
    <div class="w3-col m12 l2" :style="{ display: showSummary }">
      <div class="w3-card">
        <div style="font-size: 16pt">
          {{ $t("stats.total") }} {{ moneyFormat(statsData.total) }}
        </div>
        <div style="font-size: 16pt">
          {{ $t("stats.saved") }} {{ moneyFormat(tmpTotal) }}
        </div>
        <div style="font-size: 16pt">
          {{ $t("stats.income") }} {{ moneyFormat(statsData.income) }}
        </div>
        <div style="font-size: 16pt">
          {{ $t("stats.balance") }} {{ moneyFormat(statsData.balance) }}
        </div>
      </div>
    </div>
    <div class="w3-col m12 l10" :style="{ display: showSummary }">
      <div class="w3-card">
        <div class="w3-row-padding" style="padding-bottom: 1rem">
          <div class="w3-col l6 m6 s12">
            <label for="statStart">{{ $t("stats.start") }}</label>
            <input id="statStart" v-model="query.start" class="w3-input" type="date" @change="getStats" />
          </div>
          <div class="w3-col l6 m6 s12">
            <label for="statEnd">{{ $t("stats.end") }}</label>
            <input id="statEnd" v-model="query.end" class="w3-input" type="date" @change="getStats" />
          </div>
        </div>
        <div class="w3-row-padding" style="padding-bottom: 1rem">
          <div class="w3-col">
            <label for="statEnd">{{ $t("stats.categoryFilter") }}</label>
            <select id="catselect" v-model="categoryFilter" class="w3-input" @change="getStats">
              <option v-for="cat in categories" :value="cat" :key="cat">
                {{ cat }}
              </option>
            </select>
          </div>
        </div>
        <div class="horizontal-button-group-container">
          <span class="w3-button stat-green w3-round-xxlarge space-after date-range-btn" @click="month('back')">
            {{ $t("stats.monthBack") }}
          </span>
          <span class="stat-green w3-button w3-round-xxlarge space-after date-range-btn" @click="month('forward')">
            {{ $t("stats.monthForward") }}
          </span>
          <span class="stat-green w3-button w3-round-xxlarge space-after date-range-btn" @click="today">
            {{ $t("stats.today") }}
          </span>
          <span class="stat-green w3-button w3-round-xxlarge space-after date-range-btn" @click="thisWeek">
            {{ $t("stats.thisWeek") }}
          </span>
          <span class="stat-green w3-button w3-round-xxlarge space-after date-range-btn" @click="thisMonth">
            {{ $t("stats.thisMonth") }}
          </span>
          <span class="stat-green w3-button w3-round-xxlarge date-range-btn" @click="yearToDate">
            {{ $t("stats.yearToDate") }}
          </span>
        </div>

        <div class="w3-row-padding" style="display: none; margin-bottom: 2px; padding: 0 16px">
          <div class="w3-col l2 m2 s4 stat-green w3-button w3-round-xxlarge space-after date-range-btn"
            @click="month('back')">
            {{ $t("stats.monthBack") }}
          </div>
          <div class="w3-col l2 m2 s4 stat-green w3-button w3-round-xxlarge space-after date-range-btn"
            @click="month('forward')">
            {{ $t("stats.monthForward") }}
          </div>
          <div class="w3-col l2 m2 s4 stat-green w3-button w3-round-xxlarge space-after date-range-btn" @click="today">
            {{ $t("stats.today") }}
          </div>
          <div class="w3-col l2 m2 s4 stat-green w3-button w3-round-xxlarge space-after date-range-btn" @click="thisWeek">
            {{ $t("stats.thisWeek") }}
          </div>
          <div class="w3-col l2 m2 s4 stat-green w3-button w3-round-xxlarge date-range-btn" @click="thisMonth">
            {{ $t("stats.thisMonth") }}
          </div>
        </div>

        <div class="w3-bar">
          <button :style="{ backgroundColor: activeTab == 'spendingTab' ? '#70bc73' : 'white' }"
            class=" w3-button tab-header" @click="activeTab = 'spendingTab'">Spending</button>
          <button :style="{ backgroundColor: activeTab == 'incomeTab' ? '#70bc73' : 'white' }"
            class="w3-button tab-header" @click="activeTab = 'incomeTab'">Income</button>
        </div>

        <!-- <div>
          <transactions-table-view :transactions={ statsData.spendingGroups } />
        </div>
        <div>
          <transactions-table-view :transactions={ statsData.incomeGroups } />
        </div> -->

        <div class="transactions-table w3-responsive w3-border-top" style="max-height: 52vh; overflow-y: scroll"
          :style="{ display: activeTab == 'spendingTab' ? 'block' : 'none' }" id="spendingTab">
          <table class="w3-table w3-bordered">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">
                  {{ $t("stats.description") }}
                </th>
                <th scope="col">
                  {{ $t("stats.value") }}
                </th>
                <th scope="col">
                  {{ $t("stats.actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <div v-for="(group, index) in statsData.spendingGroups" :key="index" style="display: contents">
                <tr>
                  <td style="width: 0%">
                    <i class="fa" :class="{
                      'fa-chevron-right': !group.showItems,
                      'fa-chevron-down': group.showItems,
                    }" style="cursor: pointer" @click="showItems($event, index)" />
                  </td>
                  <td class="w3-hover-blue" :class="isSelected(group)" @click="selectAll($event, group)">
                    {{ index }}
                  </td>
                  <td>{{ moneyFormat(group["sum"]) }}</td>
                </tr>
                <div v-if="group.showItems" style="display: contents">
                  <tr v-for="(item, idx) in group.items" :key="idx">
                    <td />
                    <td class="w3-hover-blue" :class="isSelected(item)" @click="select($event, item)">
                      <div style="padding-left: 15px">
                        {{ item.desc }}
                      </div>
                    </td>
                    <td>
                      <div style="padding-left: 15px">
                        {{ moneyFormat(item.value) }}
                      </div>
                    </td>
                    <td>
                      <i class="far fa-trash-alt tr-delete" @click="deleteTransaction(item.id, index)" />
                    </td>
                  </tr>
                </div>
              </div>
            </tbody>
          </table>
        </div>

        <div class="transactions-table w3-responsive w3-border-top" style="max-height: 52vh; overflow-y: scroll;"
          :style="{ display: activeTab == 'incomeTab' ? 'block' : 'none' }" id="incomeTab">
          <table class="w3-table w3-bordered">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">
                  {{ $t("stats.description") }}
                </th>
                <th scope="col">
                  {{ $t("stats.value") }}
                </th>
                <th scope="col">
                  {{ $t("stats.actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <div v-for="(group, index) in statsData.incomeGroups" :key="index" style="display: contents">
                <tr>
                  <td style="width: 0%">
                    <i class="fa" :class="{
                      'fa-chevron-right': !group.showItems,
                      'fa-chevron-down': group.showItems,
                    }" style="cursor: pointer" @click="showItems($event, index)" />
                  </td>
                  <td class="w3-hover-blue" :class="isSelected(group)" @click="selectAll($event, group)">
                    {{ index }}
                  </td>
                  <td>{{ moneyFormat(group["sum"]) }}</td>
                </tr>
                <div v-if="group.showItems" style="display: contents">
                  <tr v-for="(item, idx) in group.items" :key="idx">
                    <td />
                    <td class="w3-hover-blue" :class="isSelected(item)" @click="select($event, item)">
                      <div style="padding-left: 15px">
                        {{ item.desc }}
                      </div>
                    </td>
                    <td>
                      <div style="padding-left: 15px">
                        {{ moneyFormat(item.value) }}
                      </div>
                    </td>
                    <td>
                      <i class="far fa-trash-alt tr-delete" @click="deleteTransaction(item.id, index)" />
                    </td>
                  </tr>
                </div>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="w3-col m12 l12" :style="{ display: showMonthly }">
      <div class="w3-card">
        <input id="num" v-model="numOfMonths" type="number" name="numOfMonth" @change="updateMonthlyChart" />
        <div style="max-height: 80vh">
          <canvas id="myChart" height="116" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stats from "../apiClient/stats";
import Transactions from "../apiClient/transactions";
import Categories from "../apiClient/categories";
import Chart from "chart.js";
import Formatter from "../utils/formatters.js";
//import TransactionBarChart from "./TransactionBarChart.vue";

import {
  add,
  sub,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export default {
  name: "Stats",
  // components: {
  //   TransactionBarChart,
  // },
  data: function () {
    var now = new Date();
    var monthStart = new Date(now.setDate(1)).toISOString().split("T")[0];
    now = new Date().toISOString().split("T")[0];
    return {
      statsData: {},
      activeTab: 'spendingTab',
      query: {
        start: monthStart,
        end: now,
      },
      tmpTotal: 0,
      monthly: [],
      showSummary: "block",
      showMonthly: "none",
      numOfMonths: 5,
      categories: ["No filter"],
      categoryFilter: "No filter",
      transactions: {
        data: [],
        labels: [],
      },
      showItemsMap: {
        incomeGroups:  [],
        spendingGroups: []
      }
      
    };
  },
  created: function () {
    this.getStats();
    this.getMonthly();
    var self = this;
    Categories.getNames().then((data) => {
      self.categories = ["No filter"].concat(data.names);
    });
  },
  methods: {
    isActiveTab: (tabName) => {
      if (this?.activeTab == tabName) {
        return 'block'
      } else {
        return 'none'
      }
    },
    getMonthly: function () {
      var self = this;
      Stats.get("/monthly?months=" + this.numOfMonths).then(function (data) {
        self.monthly = data;
        let ctx = document.getElementById("myChart").getContext("2d");
        self.chart = new Chart(ctx, {
          type: "line",
          responsive: true,
          data: {
            labels: data.labels,
            datasets: [
              {
                label: "End of month summary",
                data: data.data,
                fill: true,
                borderColor: "#8e80a6",
                tension: 0.1,
                pointHoverRadius: 10,
                backgroundColor: "#a9c6c9",
              },
            ],
          },
          options: {
            tooltips: {
              callbacks: {
                label: function (item) {
                  return Formatter.money(item.value);
                },
              },
            },
          },
        });
      });
    },
    updateMonthlyChart() {
      var self = this;
      Stats.get("/monthly?months=" + this.numOfMonths).then(function (data) {
        self.monthly = data;
        self.chart.events = [];
        self.chart.data.labels = data.labels;
        self.chart.data.datasets[0].data = data.data;
        self.chart.update();
      });
    },
    getStats: function () {
      var self = this;
      let query = "?start=" + this.query.start + "&" + "end=" + this.query.end;
      if (this.categoryFilter != "No filter") {
        query = query.concat("&category=" + this.categoryFilter);
      }
      Stats.get(query).then(function (data) {
        self.statsData = data
        self.showItemsMap.spendingGroups.forEach((e) => {
          self.statsData.spendingGroups[e.index].showItems = e.state
        })
        self.showItemsMap.incomeGroups.forEach((e) => {
          self.statsData.incomeGroups[e.index].showItems = e.state
        })
        self.showItemsMap = {        incomeGroups:  [],
        spendingGroups: []}
        //        self.tmpGroups = [];
        // self.transactions = {
        //   data: [],
        //   labels: [],
        // };

        // for (const value of Object.values(self.spendingGroups)) {
        //   value.items.forEach((item) => {
        //     self.tmpGroups.push(item);
        //   });
        // }

        // self.tmpGroups.sort((a, b) => {
        //   if (a.created_at < b.created_at) {
        //     return -1;
        //   }

        //   if (a.created_at > b.created_at) {
        //     return 1;
        //   }
        //   return 0;
        // });

        // for (const item of self.tmpGroups) {
        //   self.transactions.data.push(item.value);
        //   const d = new Date(item.created_at);
        //   self.transactions.labels.push(d.toLocaleDateString());
        // }
        // self.total = data["total_spending"];
        // self.income = data["income"];
        // self.balance = data["balance"];
      });
    },
    moneyFormat: Formatter.money,
    select: function (event, item) {
      item.selected = !item.selected
      if (item.selected) {
        this.statsData.total -= item.value;
        this.tmpTotal += -1 * item.value;
      } else {
        this.statsData.total += item.value;
        this.tmpTotal -= -1 * item.value;
      }
    },
    selectAll: function (event, group) {
      group.showItems = group.showItems ? true : !group.showItems
      group.items.forEach( item => {
        this.select(event, item)
      })
    },
    showItems: function (event, index) {
      if (this.activeTab == 'spendingTab') {
        this.statsData.spendingGroups[index].showItems = !this.statsData.spendingGroups[index].showItems;
        this.showItemsMap.spendingGroups.push(
          {index: index, state: this.statsData.spendingGroups[index].showItems}
        )
      } else {
        this.statsData.incomeGroups[index].showItems = !this.statsData.incomeGroups[index].showItems;
        this.showItemsMap.incomeGroups.push(
          {index: index, state: this.statsData.incomeGroups[index].showItems}
        )
      }

    },
    isSelected: function (tr) {
      return {
        "w3-blue": tr["selected"],
      };
    },
    today() {
      let today = new Date().toISOString().slice(0, 10);
      this.query.start = today;
      this.query.end = today;
      this.getStats();
    },
    thisWeek() {
      let today = new Date();
      this.query.start = startOfWeek(today).toISOString().slice(0, 10);
      this.query.end = endOfWeek(today).toISOString().slice(0, 10);
      this.getStats();
    },
    thisMonth() {
      let today = new Date();
      let start = startOfMonth(today);
      start = add(start, { days: 1 });
      this.query.start = start.toISOString().slice(0, 10);
      this.query.end = endOfMonth(today).toISOString().slice(0, 10);
      this.getStats();
    },
    yearToDate() {
      let today = new Date();
      let start = new Date(today.getFullYear(), 1, 1);
      this.query.start = start.toISOString().slice(0, 10);
      this.getStats();
    },
    month(direction) {
      let startDay = startOfMonth(new Date(this.query.start));
      if (direction == "back") {
        startDay = sub(startDay, { months: 1 });
        startDay = add(startDay, { days: 1 });
      } else if (direction == "forward") {
        startDay = add(startDay, { months: 1 });
        startDay = add(startDay, { days: 1 });
      }

      let endDay = endOfMonth(startDay);
      this.query.start = startDay.toISOString().slice(0, 10);
      this.query.end = endDay.toISOString().slice(0, 10);
      this.getStats();
    },
    showSummaryBtn() {
      this.showMonthly = "none";
      this.showSummary = "block";
    },
    showMonthlyBtn() {
      this.showMonthly = "block";
      this.showSummary = "none";
    },
    deleteTransaction(id) {
      var self = this;
      Transactions.remove(id).then(function () {
        self.getStats()
        self.emitter.emit("UpdateCurrentCash");
      });
    }
  },
};
</script>

<style>
.stat-green {
  background-color: #70bc73;
}

.space-after {
  margin-right: 5px;
}

.date-range-btn {
  padding: 10px !important;
}

.horizontal-button-group-container {
  display: block;
  margin-bottom: 5px;
}

.horizontal-button-group-container div {
  width: 30%;
  margin: 5px;
  display: inline;
}

.tab-header {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-right: 0.5rem;
}
</style>

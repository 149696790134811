import Api from '../index'

var path = '/stats'

async function get(query) {
    return Api.get(path + query)
}

export default {
    get: get
}

<template>
  <div class="w3-col">
    <div class="w3-row-padding">
      <div class="w3-col">
        <h1>{{ $t("menu.categories") }}</h1>
        <div class="w3-card">
          <h3>{{ $t("categories.help") }}</h3>
          <div class="w3-row-padding">
            <div class="w3-col w3-half">
              <label
                for="cat"
              >{{ $t("categories.name") }}
                <i
                  id="nametip"
                  class="far fa-question-circle"
                />
              </label>
              <input
                id="cat"
                v-model="userInput.name"
                class="w3-input"
                type="text"
                :placeholder="$t('categories.name')"
                @keyup.enter="add"
                autocomplete="false"
              >
            </div>
            <div class="w3-col w3-half">
              <label
                for="words"
              >{{ $t("categories.words") }}

                <i
                  id="wordstip"
                  class="far fa-question-circle"
                />
              </label>
              <input
                id="words"
                v-model="userInput.words"
                class="w3-input"
                type="text"
                :placeholder="$t('categories.words')"
                @keyup.enter="add"
              >
            </div>
          </div>
          <hr>
          <table class="w3-table w3-bordered">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t("categories.nameTh") }}
                </th>
                <th scope="col">
                  {{ $t("categories.wordsTh") }}
                </th>
                <th scope="col">
                  {{ $t("categories.actionTh") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cat, index) in categories"
                :key="cat.name"
              >
                <td v-if="!editMode(index)">
                  {{ cat.name }}
                </td>
                <td v-if="editMode(index)">
                  <input
                    v-model="cat.name"
                    type="text"
                    @keyup.enter="update(cat.id, index)"
                  >
                </td>
                <td v-if="!editMode(index)">
                  {{ cat.words }}
                </td>
                <td v-if="editMode(index)">
                  <input
                    v-model="cat.words"
                    type="text"
                    @keyup.enter="update(cat.id, index)"
                  >
                </td>
                <td class="td-delete">
                  <i
                    class="far fa-edit tr-delete"
                    style="margin-right: 5px"
                    @click="edit(index)"
                  />
                  <i
                    class="far fa-trash-alt tr-delete"
                    @click="remove(cat.id, index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Categories from "../apiClient/categories";

export default {
  name: "Categories",
  data: function () {
    return {
      categories: [],
      userInput: {
        name: "",
        words: "",
      },
    };
  },
  created: function () {
    var self = this;
    Categories.get().then(function (data) {
      self.categories = data;
    });
  },
  mounted() {
    let nameTT = this.$t("categories.tooltip.name");
    let wordsTT = this.$t("categories.tooltip.words");
    this.tippy("#nametip", {
      content: nameTT,
      trigger: "click",
    });
    this.tippy("#wordstip", {
      content: wordsTT,
      trigger: "click",
    });
  },
  methods: {
    add: function () {
      if (this.userInput.name == "") {
        return;
      } else if (this.userInput.words == "") {
        return;
      }
      if (this.userInput.words.split(",").length > 5) {
        //alert("You can't have more than 5 words, please shorten the list");
        //return;
      }
      var self = this;
      Categories.post(this.userInput).then(function (data) {
        self.categories.unshift({ ...data });
        self.userInput.name = "";
        self.userInput.words = "";
      });
    },
    remove: function (id, index) {
      var self = this;
      Categories.remove(id).then(function () {
        self.categories.splice(index, 1);
      });
    },
    edit: function (index) {
      this.categories[index].editMode = !this.categories[index].editMode;
    },
    editMode: function (index) {
      return this.categories[index].editMode;
    },
    update: function (id, index) {
      var self = this;
      Categories.put(id, this.categories[index]).then(function () {
        self.edit(index);
      });
    },
  },
};
</script>

<style>
</style>

<template>
  <header
    class="w3-row-padding w3-center current-cash-container hide-on-mobile"
  >
    <div class="w3-col l2 m6">
      <div class="w3-card">
        <p class="money-section">
          {{ currentCashPretty }}
        </p>
        <p class="label">{{ $t("currentCash.sum") }}</p>
      </div>
    </div>
    <div class="w3-col l2 m6">
      <div class="w3-card">
        <p class="money-section">
          {{ monthlyIncome }}
        </p>
        <p class="label">{{ $t("currentCash.income") }}</p>
      </div>
    </div>
    <div class="w3-col l2 m6">
      <div class="w3-card">
        <p class="money-section">
          {{ monthlyExpense }}
        </p>
        <p class="label">{{ $t("currentCash.out") }}</p>
      </div>
    </div>
    <div class="w3-col l2 m6">
      <div class="w3-card">
        <p class="money-section">
          {{ spendableThisMonth }}
        </p>
        <p class="label">{{ $t("currentCash.money") }}</p>
      </div>
    </div>
    <div class="w3-col l2 m6">
      <div class="w3-card">
        <p class="money-section">
          {{ cashFlow }}
        </p>
        <p class="label">{{ $t("currentCash.cashflow") }}</p>
      </div>
    </div>
    <div class="w3-col l2 m6">
      <div class="w3-card">
        <p class="money-section">
          {{ emergencyFund }}
        </p>
        <p class="label">{{ $t("currentCash.emergencyFund") }}</p>
      </div>
    </div>
  </header>
  <header class="w3-row-padding current-cash-container hide-on-desktop">
    <div class="w3-col">
      <div class="w3-card">
        <div class="w3-row">
          <div class="w3-col s6 m6">
            {{ $t("currentCash.sum") }}
          </div>
          <div class="w3-col s6 m6 w3-right-align">
            {{ currentCashPretty }}
          </div>
        </div>
        <div class="w3-row">
          <div class="w3-col s6 m6">
            {{ $t("currentCash.income") }}
          </div>
          <div class="w3-col s6 m6 w3-right-align">
            {{ monthlyIncome }}
          </div>
        </div>
        <div class="w3-row">
          <div class="w3-col s6 m6">
            {{ $t("currentCash.out") }}
          </div>
          <div class="w3-col s6 m6 w3-right-align">
            {{ monthlyExpense }}
          </div>
        </div>
        <div class="w3-row">
          <div class="w3-col s6 m6">
            {{ $t("currentCash.money") }}
          </div>
          <div class="w3-col s6 m6 w3-right-align">
            {{ spendableThisMonth }}
          </div>
        </div>
        <div class="w3-row">
          <div class="w3-col s6 m6">
            {{ $t("currentCash.cashflow") }}
          </div>
          <div class="w3-col s6 m6 w3-right-align">
            {{ cashFlow }}
          </div>
        </div>
        <div class="w3-row">
          <div class="w3-col s6 m6">
            {{ $t("currentCash.emergencyFund") }}
          </div>
          <div class="w3-col s6 m6 w3-right-align">
            {{ emergencyFund }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CC from "../apiClient/currentCash";
import formatters from "../utils/formatters";

export default {
  name: "CurrentCash",
  data: function () {
    return {
      currentCashPretty: "0",
      monthlyIncome: "0",
      monthlyExpense: "0",
      leftToSpend: "0",
      spendableThisMonth: "0",
      cashFlow: 0,
      emergencyFund: 0,
    };
  },
  created: function () {
    this.getCurrentCash();
    this.emitter.on("UpdateCurrentCash", this.getCurrentCash);
  },
  methods: {
    getCurrentCash: function () {
      var self = this;
      CC.get().then(function (data) {
        if (!data) {
          return
        }
        self.currentCashPretty = formatters.money(data.current_cash); //+ " " + data.currency;
        self.monthlyIncome = formatters.money(data.monthly_income); //+ " " + data.currency;
        self.monthlyExpense = formatters.money(data.monthly_recurring_expense); //+
        self.spendableThisMonth = formatters.money(data.spending_money); //+ " " + data.currency;
        self.cashFlow = formatters.money(data.cash_flow); //+ " " + data.currency;
        self.leftToSpend = formatters.money(data.spendable_this_month); // + " " + data.currency;
        if(!data.monthly_recurring_expense) {
          data.monthly_recurring_expense = 0
        }
        self.emergencyFund = formatters.money(
          data.monthly_recurring_expense * -6
        );
      });
    },
  },
};
</script>

<style>
.money-section {
  font-weight: bold;
}

.current-cash-container p {
  margin: 0;
  text-align: left;
}

.label {
  font-size: calc(10px + 0.3vw);
  word-wrap: break-word;
}

@media only screen and (min-width: 769px) {
  .current-cash-container {
    font-size: x-large;
  }

  .current-cash-container.w3-row {
    border-bottom: 1px solid grey;
  }
}
</style>

import Api from '../index'

let path = '/recurring_transactions'

async function post(input) {
    return Api.post(path, input)
}

async function get() {
    return Api.get(path)
}

async function remove(input) {
    return Api.remove(path + '/' + input)
}

export default {
    post: post,
    get: get,
    remove: remove,
}

import Api from '../index'

let path = '/summary'

async function get() {
    return Api.get(path)
}

export default {
    get: get
}

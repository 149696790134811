import Api from '../index'

var path = '/user/settings'
async function post(input) {
    return Api.post(path, input)
}

async function setLanguage(input) {
    return Api.post(path + '/language', input)
}

async function get() {
    return Api.get(path)
}

export default {
    post: post,
    setLanguage: setLanguage,
    get: get
}

import Api from '../index'

var path = '/orders'
async function post(input) {
    return Api.postResponse(path, input)
}

async function get() {
    return Api.get(path)
}

export default {
    post: post,
    get: get
}

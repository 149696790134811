<template>
  <div class="w3-row-padding">
    <div class="w3-col">
      <h1>{{ $t("menu.planner") }}</h1>
    </div>
    <div class="w3-col m4">
      <div class="w3-card">
        <recurring-transactions />
      </div>
    </div>
    <div class="w3-col m8">
      <div class="w3-card">
        <forecast />
      </div>
    </div>
  </div>
</template>

<script>
import Forecast from "./Forecast.vue";
import RecurringTransactions from "./RecurringTransactions.vue";

export default {
  name: "Planner",
  components: {
    Forecast,
    RecurringTransactions,
  },
};
</script>

<style>
</style>

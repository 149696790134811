<template>
  <div>
    <div class="w3-card login">
      <div style="margin: auto; text-align: center">
        <img
          src="logo3.svg"
          style="width: 100px; margin-top: 20px; margin-bottom: 20px"
        >
      </div>
      <!-- <h1 class="w3-center">Login</h1> -->
      <label>{{ $t("reset.password1") }}</label>
      <input
        v-model="password1"
        class="w3-input w3-border w3-round-large"
        type="password"
        :placeholder="$t('reset.password1')"
        style="margin-bottom: 1em"
      >

      <label for="">{{ $t("reset.password2") }}</label>

      <input
        v-model="password2"
        class="w3-input w3-border w3-round-large"
        type="password"
        :placeholder="$t('reset.password2')"
        style="margin-bottom: 2em"
        @keyup.enter="login"
      >
      <div v-show="noMatch">
        {{ $t("reset.noMatch") }}
      </div>
      <button
        class="w3-block w3-button w3-green"
        style="font-size: 24px; border-radius: 38px"
        type="submit"
        @click="resetPassword"
      >
        {{ $t("reset.resetButton") }}
      </button>
    </div>
  </div>
</template>

<script>
import PWReset from './apiClient/pwReset'
export default {
  name: "PasswordReset",
  data: function () {
    return {
      password1: '',
      password2: '',
      noMatch: false
    };
  },
  methods: {
    resetPassword: function () {
      if (this.password1 != this.password2) {
        this.noMatch = true
        return
      }

      let token = this.$route.query.tk;
      let self = this
      PWReset.postNewPassword({token: token, password1: this.password1, password2: this.password2})
      .then(function(data) {
        if (data.status == 200) {
          self.$router.push('/login')
        }
      })
    },
  },
};
</script>

<style>

</style>

import Api from '../index'

var path = '/invite'
async function post(input) {
    return Api.post(path, { email: input })
}

async function accept(input) {
    return Api.post(path + "/accept", input)
}

export default {
    inviteUser: post,
    accept: accept
}

import Api from '../index'

let path = '/forecast'
function post(input) {
    return Api.post(path, input)
}

export default {
    post: post
}

import Numeral from 'numeraljs'
//import Language from '../languageFunctions'

export default {
  money: function(input) {
    return Numeral(input).format('0,0')
  },
  date: function(dateString) {
    let d = Date.parse(dateString)
    //return (new Date(d)).toLocaleDateString(Language.get())
    console.log(d);
    d = new Date(d)
    return d.toISOString().split('T')[0]
  },
  percentage: function(value) {
    value = value.toString()
    let first = value.split('.')[0]
    let last = value.split('.')[1][0] + value.split('.')[1][1]
    return first + '.' + last + '%'
  }
}

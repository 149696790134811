import Api from '../index'

let path = '/forgot-password'

async function post(input) {
    return Api.postResponse(path, input)
}

async function postNewPassword(input) {
    return Api.postResponse('/user/reset-password', input)
}

export default {
    post: post,
    postNewPassword: postNewPassword
}

function getLanguage() {
    let language = 'en-EN'
    if (localStorage.getItem('language') &&
      localStorage.getItem('language') != 'undefined' &&
      localStorage.getItem('language') != 'null') {
        language = localStorage.getItem('language')
    }

    if (window.ENV && window.ENV.language && window.ENV.language != 'null') {
        language = window.ENV.language
    }

    return language
}

function setLanguage(lang) {
    localStorage.setItem("language", lang);
    if (window.ENV) {
        window.ENV.language = lang;
    } else {
        window.ENV = { language: lang };
    }
}

export default {
    get: getLanguage,
    set: setLanguage
}

import Api from '../index'

let path = '/transactions'

async function get() {
    return Api.get(path)
}

async function post(input) {
    return Api.postResponse(path, input)
}

async function remove(id) {
    return Api.remove(path + '/' + id)
}

function put(id, input) {
    return Api.put(path + '/' + id, input)
}

export default {
    get: get,
    post: post,
    remove: remove,
    put: put
}

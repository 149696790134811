<template>
  <div class="w3-row-padding">
    <div class="w3-col">
      <h1>{{ $t("menu.import") }}</h1>
      <div class="w3-card">
        <h3>{{ $t('import.help') }}</h3>
        <input
          id="file"
          ref="file"
          class="w3-input"
          type="file"
          accept=".csv"
          @change="handleFileUpload()"
        >
        <h3>{{ $t('import.importDate') }} {{ lastTransactionDate }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import ImportData from "../apiClient/importData";
import Formatter from "../utils/formatters.js";

export default {
  name: "ImportData",
  data: function () {
    return {
      file: "",
      lastTransactionDate: ""
    };
  },
  created: function () {
    var self = this;
    ImportData.get().then((data)=> {
      if(data.date == 'import.noImportYet') {
        self.lastTransactionDate = self.$t(data.date)
      } else {
        self.lastTransactionDate = Formatter.date(data.date)
      }
    })
  },
  methods: {
    handleFileUpload: function () {
      var formData = new FormData();
      var self = this;
      this.file = this.$refs.file.files[0];
      formData.append("file", this.file);
      ImportData.post(formData).then(function (resp) {
        if (resp.ok) {
          alert(self.$t('import.success'));
        } else {
          alert(self.$t('import.failure'));
        }
        self.$refs.file.value = null;
      });
    },
  },
};
</script>

<style>
</style>

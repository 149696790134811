<template>
  <!-- <div class=""><pulse-loader :loading="true"></pulse-loader></div> -->
  <div class="subscription-container">
    <div class="w3-row-padding">
      <div class="w3-col">
        <div class="sub-header">
          <h1>Előfizetés</h1>
          <button
            class="w3-bar-item w3-button w3-round w3-red"
            @click="logout()"
          >
            {{ $t("menu.logout") }}
          </button>
        </div>
      </div>
      <div class="w3-col">
        <div class="w3-card" v-if="showBankData">
          <h3>
            Kérlek utald át a megfelelő összeget a Szolgáltató bankszámlaszámra
          </h3>
        </div>
      </div>
    </div>
    <div v-if="showBankData" class="payment-info">
      <div class="w3-row-padding">
        <div class="w3-col">
          <div class="w3-card">
            <div class="w3-row">
              <div class="w3-col w3-half">
                <h3>Szolgáltató</h3>
                <p>Név: {{ serviceProvider.name }}</p>
                <p>Cím: {{ serviceProvider.address }}</p>
                <p>Adószám: {{ serviceProvider.tax_number }}</p>
                <p>Bankszámlaszám: {{ serviceProvider.bank_account }}</p>
                <p>Telefon: {{ serviceProvider.phone }}</p>
                <p>Email: {{ serviceProvider.email }}</p>
              </div>
              <div class="w3-col w3-half">
                <h3>Vevő</h3>
                <p>Név: {{ buyer.firstName }} {{ buyer.lastName }}</p>
                <p>Cím: {{ buyerAddress() }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-row-padding">
        <div class="w3-col">
          <div class="w3-card">
            <div class="w3-row">
              <div class="w3-col w3-half">
                <h3>Díjbekérő</h3>
                <p>
                  Sorszáma: {{ serviceProvider.order_id }} (Közleményben kérjük
                  feltűntetni)
                </p>
                <p>Kelte: {{ serviceProvider.order_date }}</p>
                <p>Fizetési határidő: {{ serviceProvider.payment_due_date }}</p>
                <p>Fizetési módja: átutalás</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-row-padding">
        <div class="w3-col">
          <div class="w3-card">
            <div class="w3-row">
              <div class="w3-col w3-half">
                <h3>Megnevezés</h3>
                <p>Mennyiség: 1</p>
                <p>Nettó érték: {{ serviceProvider.price }}</p>
                <p>Áfa: 0%</p>
                <p>Áfa összege: 0 Ft</p>
                <p>Bruttó érték: {{ serviceProvider.price }}</p>
                <p>
                  <strong>Fizetendő: {{ serviceProvider.price }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-row-padding" v-if="!showBankData">
      <div class="w3-col">
        <div class="w3-card">
          <p>Véget ért a próbaidőszak</p>
          <p>Kérlek válassz az alábbi opciók közül</p>
          <form class="order-form">
            <fieldset>
              <legend>Csomag</legend>
              <div class="w3-row">
                <div class="w3-half">
                  <input
                    type="radio"
                    name="subscription_type"
                    class="w3-radio"
                    id="monthly"
                    value="monthly"
                    checked="true"
                    v-model="order.subscriptionType"
                  />
                  <label for="monthly">1 hónap, 2490 Ft</label>
                </div>
                <div class="w3-half">
                  <input
                    type="radio"
                    name="subscription_type"
                    class="w3-radio"
                    id="annually"
                    value="annually"
                    v-model="order.subscriptionType"
                  />
                  <label for="annually">1 év, 27390 Ft</label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Számlázási adatok</legend>
              <label for="fname">Vezetéknév</label>
              <input
                type="text"
                id="fname"
                name="fname"
                v-model="order.firstName"
              />
              <label for="lname">Keresztnév</label>
              <input
                type="text"
                id="lname"
                name="lname"
                v-model="order.lastName"
              />
              <label for="postal">Irányítószám</label>
              <input
                type="text"
                id="postal"
                name="postal"
                v-model="order.postalCode"
              />
              <label for="city">Város</label>
              <input type="text" id="city" name="city" v-model="order.city" />
              <label for="street">Utca, házszám</label>
              <input
                type="text"
                id="street"
                name="street"
                v-model="order.streetAndHouse"
              />
            </fieldset>
          </form>
          <button
            class="
              w3-button w3-round-xxlarge w3-green w3-large w3-padding-large
            "
            @click="doOrder()"
            :disabled="formIsNotFilled()"
          >
            Megrendelem
          </button>
        </div>
      </div>
      <div class="w3-col">
        <div class="w3-card plan-container">
          <div>Fiók törlése</div>
          <div>
            <button
              class="
                w3-button w3-round-xxlarge w3-green w3-large w3-padding-large
              "
              @click="deleteAccount()"
            >
              Ezt választom
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subscription from "./apiClient/subscription";
import Formatters from "./utils/formatters";
import Login from "./apiClient/login";

export default {
  name: "Subscription",
  data: function () {
    return {
      serviceProvider: {
        accountNumber: "",
        value: "",
        orderId: "",
      },
      order: {
        firstName: "",
        lastName: "",
        postalCode: "",
        city: "",
        streetAndHouse: "",
        subscriptionType: "monthly",
      },
      showBankData: false,
      showForm: false,
      loading: true,
    };
  },
  beforeMount: function () {
    var self = this;
    Subscription.get().then((resp) => {
      if (resp.order_id) {
        self.loading = false;
        self.serviceProvider = resp;
        self.serviceProvider.price = Formatters.money(resp.price) + " Ft";
        self.serviceProvider.order_date = Formatters.date(resp.order_date);
        self.serviceProvider.payment_due_date = Formatters.date(
          resp.payment_due_date
        );
        self.buyer = resp.invoice;

        self.showBankData = true;
      }
    });
  },
  methods: {
    doOrder: function () {
      this.loading = true;
      var self = this;
      Subscription.post(this.order)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            self.$router.replace("/login");
          }
        })
        .then((resp) => {
          if (resp.order_id) {
            self.loading = false;
            self.serviceProvider = resp;
            self.serviceProvider.price = Formatters.money(resp.price) + " Ft";
            self.serviceProvider.order_date = Formatters.date(resp.order_date);
            self.serviceProvider.payment_due_date = Formatters.date(
              resp.payment_due_date
            );
            self.buyer = resp.invoice;

            self.showBankData = true;
          }
        });
    },
    deleteAccount: function (type) {
      var self = this;
      Subscription.post({ plan: type })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            self.$router.replace("/login");
          }
        })
        .then((resp) => {
          if (resp.status == "started") {
            self.showBankData = true;
          }
        });
    },
    show: function (type) {
      if (type == "monthly") {
        return true;
      }
      return false;
    },
    formIsNotFilled: function () {
      return (
        this.order.firstName == "" ||
        this.order.lastName == "" ||
        this.order.postalCode == "" ||
        this.order.city == "" ||
        this.order.streetAndHouse == ""
      );
    },
    buyerAddress: function () {
      return this.buyer.postalCode.concat(
        " ",
        this.buyer.city,
        ", ",
        this.buyer.streetAndHouse
      );
    },
    logout: function () {
      var self = this;
      Login.logout().then(function () {
        self.$router.replace("/login");
      });
    },
  },
};
</script>

<style>
.center-stuff {
  display: flex;
  justify-content: center;
}

.center-stuff .w3-col {
  padding: 6px;
}

.plan-container div {
  margin: auto;
  text-align: center;
  font-size: xx-large;
}

.payment-info {
  font-size: larger;
}

.order-form fieldset {
  margin-bottom: 5px;
  border-radius: 15px;
}

.subscription-container {
  margin: auto;
  max-width: 710px;
}

.subscription-container .w3-card {
  padding-top: 10px;
}

.subscription-container p {
  font-size: 17px;
  margin-block-start: 0px;
}

.subscription-container label {
  padding-left: 5px;
}

.sub-header {
  display: flex;
  justify-content: space-between;
}

.sub-header button {
  margin: 10px;
}
</style>

<template>
  <router-view />
</template>

<script>
import Login from "./apiClient/login";
import LanguageFunctions from "./languageFunctions"

export default {
  name: "MainApp",
  data: () => {
    return {
      loaded: true,
    };
  },
  beforeCreate: function () {
    if (window.location.hash.includes("reset?tk=")) {
      return;
    }

    var self = this;
    Login.get()
      .then(function (resp) {
        if (resp.ok) {
          self.loaded = true;
          localStorage.setItem("loggedIn", true);
        }
        return resp.json()
      })
      .then(function(data) {
        if(data.reason == "UNPAID") {
          self.$router.push("/subscription");
        } else if(data.reason == "unauthorized"){
          self.loaded = false;
          self.$router.replace("/login");
        } else {
          LanguageFunctions.set(data.settings.language);
          self.$root.$i18n.locale = data.settings.language;
          let path = window.location.hash
          path = path.substring(1, path.length)
          if (path == '/' || path == '/subscription') {
            path = '/app/transactions'  
          }
          self.$router.push(path);
        }
      })
      .catch(() => {
        self.loaded = false;
        self.$router.replace("/login");
      });
  },
};
</script>

<style>
#mainApp {
  background-color: #efefef;
}

.cssload-container *,
.cssload-container *:before,
.cssload-container *:after {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.cssload-container {
  margin: 49px auto;
  width: 58px;
  height: 29px;
}

.cssload-container > div {
  float: left;
  background: rgb(185, 108, 255);
  height: 100%;
  width: 5px;
  margin-right: 1px;
  display: inline-block;
}

.cssload-container .cssload-shaft1 {
  animation-delay: 0.06s;
  -o-animation-delay: 0.06s;
  -ms-animation-delay: 0.06s;
  -webkit-animation-delay: 0.06s;
  -moz-animation-delay: 0.06s;
}
.cssload-container .cssload-shaft2 {
  animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  -ms-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
}
.cssload-container .cssload-shaft3 {
  animation-delay: 0.17s;
  -o-animation-delay: 0.17s;
  -ms-animation-delay: 0.17s;
  -webkit-animation-delay: 0.17s;
  -moz-animation-delay: 0.17s;
}
.cssload-container .cssload-shaft4 {
  animation-delay: 0.23s;
  -o-animation-delay: 0.23s;
  -ms-animation-delay: 0.23s;
  -webkit-animation-delay: 0.23s;
  -moz-animation-delay: 0.23s;
}
.cssload-container .cssload-shaft5 {
  animation-delay: 0.29s;
  -o-animation-delay: 0.29s;
  -ms-animation-delay: 0.29s;
  -webkit-animation-delay: 0.29s;
  -moz-animation-delay: 0.29s;
}
.cssload-container .cssload-shaft6 {
  animation-delay: 0.35s;
  -o-animation-delay: 0.35s;
  -ms-animation-delay: 0.35s;
  -webkit-animation-delay: 0.35s;
  -moz-animation-delay: 0.35s;
}
.cssload-container .cssload-shaft7 {
  animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
}
.cssload-container .cssload-shaft8 {
  animation-delay: 0.46s;
  -o-animation-delay: 0.46s;
  -ms-animation-delay: 0.46s;
  -webkit-animation-delay: 0.46s;
  -moz-animation-delay: 0.46s;
}
.cssload-container .cssload-shaft9 {
  animation-delay: 0.52s;
  -o-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -moz-animation-delay: 0.52s;
}
.cssload-container .cssload-shaft10 {
  animation-delay: 0.58s;
  -o-animation-delay: 0.58s;
  -ms-animation-delay: 0.58s;
  -webkit-animation-delay: 0.58s;
  -moz-animation-delay: 0.58s;
}

.cssload-container {
  height: 39px;
  width: 88px;
  overflow: hidden;
}
.cssload-container > div {
  width: 8px;
  position: relative;
  bottom: -2px;
  margin-top: 36px;
  height: 3px;
  transform: skewY(0deg);
  animation: cssload-wave 1.73s infinite ease-in-out;
  -o-animation: cssload-wave 1.73s infinite ease-in-out;
  -ms-animation: cssload-wave 1.73s infinite ease-in-out;
  -webkit-animation: cssload-wave 1.73s infinite ease-in-out;
  -moz-animation: cssload-wave 1.73s infinite ease-in-out;
}

@keyframes cssload-wave {
  25% {
    transform: skewY(25deg);
  }
  50% {
    height: 100%;
    margin-top: 0;
    background: rgb(86, 215, 198);
    transform: skewY(0);
  }
  75% {
    transform: skewY(-25deg);
  }
}

@-o-keyframes cssload-wave {
  25% {
    -o-transform: skewY(25deg);
  }
  50% {
    height: 100%;
    margin-top: 0;
    background: rgb(86, 215, 198);
    -o-transform: skewY(0);
  }
  75% {
    -o-transform: skewY(-25deg);
  }
}

@-ms-keyframes cssload-wave {
  25% {
    -ms-transform: skewY(25deg);
  }
  50% {
    height: 100%;
    margin-top: 0;
    background: rgb(86, 215, 198);
    -ms-transform: skewY(0);
  }
  75% {
    -ms-transform: skewY(-25deg);
  }
}

@-webkit-keyframes cssload-wave {
  25% {
    -webkit-transform: skewY(25deg);
  }
  50% {
    height: 100%;
    margin-top: 0;
    background: rgb(86, 215, 198);
    -webkit-transform: skewY(0);
  }
  75% {
    -webkit-transform: skewY(-25deg);
  }
}

@-moz-keyframes cssload-wave {
  25% {
    -moz-transform: skewY(25deg);
  }
  50% {
    height: 100%;
    margin-top: 0;
    background: rgb(86, 215, 198);
    -moz-transform: skewY(0);
  }
  75% {
    -moz-transform: skewY(-25deg);
  }
}

@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .w3-row-padding {
    padding: 0px;
  }

  .w3-row-padding .w3-col {
    padding: 0px;
  }

  .w3-container {
    padding: 0px;
  }
}
</style>

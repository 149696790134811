let apiHost = process.env.VUE_APP_API_URL
let baseRequestJSON = JSON.stringify({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    cache: 'no-cache',
    redirect: 'follow',
    credentials: 'include',
    referrer: 'no-referrer',
})

async function fetchWithErrorHandling(path, request) {
    return await fetch(path, request).then((resp) => {
        return resp.json()
    })
        .then((resp) => {
            if (resp.reason == 'UNPAID') {
                window.location.hash = '/subscription'
                throw new Error('unpaid')
            } else if (resp.reason == 'unauthorized') {
                window.location.hash = '/login'
                throw new Error('unauthorized')
            }

            return resp
        })
        .catch(() => {
            //console.log(err);
            window.location.hash = '/login'
        })

}

export default {
    apiHost: apiHost,
    post: async function (path, input) {
        let request = JSON.parse(baseRequestJSON)
        request.method = 'POST'
        request.body = JSON.stringify(input)
        const response = await fetchWithErrorHandling(apiHost + path, request);
        return response
    },
    put: async function (path, input) {
        let request = JSON.parse(baseRequestJSON)
        request.method = 'PUT'
        request.body = JSON.stringify(input)
        const response = await fetchWithErrorHandling(apiHost + path, request);
        return response
    },
    get: async function (path) {
        let request = JSON.parse(baseRequestJSON)
        request.method = 'GET'
        return await fetchWithErrorHandling(apiHost + path, request);
    },
    remove: function (path) {
        let request = JSON.parse(baseRequestJSON)
        request.method = 'DELETE'
        return fetch(apiHost + path, request);
    },
    getResponse: function (path) {
        let request = JSON.parse(baseRequestJSON)
        request.method = 'GET'
        return fetch(apiHost + path, request);
    },
    postResponse: function (path, input) {
        let request = JSON.parse(baseRequestJSON)
        request.method = 'POST'
        request.body = JSON.stringify(input)
        return fetch(apiHost + path, request);
    }
}

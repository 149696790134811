import Api from '../index'

var path = '/categories'
async function post(input) {
    return Api.post(path, input)
}

function get() {
    return Api.get(path)
}

function put(id, input) {
    return Api.put(path + '/' + id, input)
}

async function remove(id) {
    return Api.remove(path + '/' + id)
}

function getNames() {
    return Api.get(path + '/names')
}

export default {
    post: post,
    get: get,
    put: put,
    remove: remove,
    getNames: getNames
}
